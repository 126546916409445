/* eslint-disable promise/always-return */
/* eslint-disable promise/catch-or-return */
/* eslint-disable @typescript-eslint/prefer-ts-expect-error */
/* eslint-disable @typescript-eslint/ban-ts-comment */
'use client'

import {
  useState,
  useCallback,
  type PropsWithChildren,
  type ChangeEvent,
  useEffect,
} from 'react'
import { usePathname, useRouter, useSearchParams } from 'next/navigation'

import { Icon } from '@/icons/icon'
import parse from 'html-react-parser'
import { routeCalculatorRelative } from '@/lib/route-calculator'
import matchPath from '@/lib/utils/match-path'
import { hostURL } from '@/utils/functions/url-utils'
import { ButtonIcon } from '../elements/button-icon/button-icon'
import { Combobox } from '@headlessui/react'
import { useDebounce, useLocation } from 'react-use'
import { type GetAutoCompleteQuery } from '@/lib/generated/graphql'
import { isNullOrEmpty } from '@/utils/string-extensions'

const SearchBarContainer = ({ children }: PropsWithChildren) => (
  <div className='relative w-full'>{children}</div>
)

const SearchIconContainer = ({ children }: PropsWithChildren) => (
  <div className='absolute right-6 top-0 inline-block sm:right-2'>
    {children}
  </div>
)

const useSearchFromUri = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()
  const queryParam = searchParams.get('q') as string
  let searchText = ''

  if (queryParam) {
    searchText = queryParam
  } else {
    const match = matchPath('search/**/:searchText', pathname)
    searchText = match.searchText ?? ''
  }

  try {
    searchText = decodeURIComponent(searchText)
  } catch {
    // nothing
  }

  return searchText ?? ''
}

const getAutoComplete = async (searchTerm: string) => {
  return await fetch(`${hostURL()}/api/search`, {
    body: JSON.stringify({
      searchTerm,
    }),
    method: 'POST',
  })
}

export const SearchBox = () => {
  const searchString = useSearchFromUri()
  const [searchText, setSearchText] = useState(searchString)
  const [searchOptions, setSearchOptions] = useState<string[]>([])
  const router = useRouter()
  const location = useLocation()

  useEffect(() => {
    if (!location.pathname?.startsWith(routeCalculatorRelative.search)) {
      setSearchText('')
    }
  }, [location])

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  useDebounce(
    () => {
      if (searchText?.trim()?.length < 3) {
        setSearchOptions([])
      } else {
        getAutoComplete(searchText)
          .then(async (response) => await response.json())
          .then((data: GetAutoCompleteQuery) => {
            if ((data.getAutocomplete?.items?.length ?? 0) > 0) {
              setSearchOptions((data.getAutocomplete?.items as string[]) ?? [])
            }
          })
          .catch(() => {
            setSearchOptions([])
          })
      }
    },
    300,
    [searchText],
  )

  const submit = useCallback(
    (searchText: string) => {
      router.push(
        routeCalculatorRelative.searchRoute(
          encodeURIComponent(searchText.replaceAll(/<\/?[^>]+(>|$)/g, '')),
        ),
      )
      setSearchText(searchText)
    },
    [router],
  )

  return (
    <SearchBarContainer>
      <Combobox value=''>
        <Combobox.Button className={'h-10 w-full'}>
          <Combobox.Input
            data-testid={`searchbox-textfield`}
            onKeyDown={(event) => {
              const target = event.target as HTMLInputElement
              if (event.key === 'Enter' && target.value?.length > 0) {
                submit(target.value)
                event.currentTarget.blur()
                return
              }

              // don't interfere with OS commands
              if (event.shiftKey || event.ctrlKey) {
                return
              }

              // bind Home and End keys
              switch (event.key) {
                case 'Home':
                  target.setSelectionRange(0, 0)
                  break
                case 'End':
                  target.setSelectionRange(
                    target.value.length,
                    target.value.length,
                  )
              }
            }}
            placeholder='Search millions of specials'
            className={({ open }) =>
              `h-10 max-h-10 w-full ${open && searchOptions.length > 0 ? 'rounded-t-[20px]' : 'rounded-3xl'} border-none`
            }
            value={(searchText ?? '')?.replaceAll?.(/<\/?[^>]+(>|$)/g, '')}
            onChange={onSearchChange}
          />
        </Combobox.Button>
        <div
          className={`${isNullOrEmpty(searchText.trim()) && 'hidden'} absolute right-16 top-0 flex h-10 items-center`}
        >
          <Icon
            name='remove-regular'
            size='s'
            color='dark'
            variant='dark'
            onClick={() => {
              setSearchText('')
            }}
          />
        </div>
        <Combobox.Options
          className={({ open }) =>
            `absolute top-[40px] z-50 max-h-64 w-full overflow-auto rounded-b-[20px] bg-white ${open && searchOptions.length > 0 ? 'border-solid border-[1px]  border-grey-200 border-t-transparent' : ''}`
          }
        >
          {searchOptions.map((option, index) => (
            <Combobox.Option
              key={option}
              value={option}
              onClick={() => {
                submit(option)
              }}
              className='mx-6 my-1 cursor-pointer bg-white last:rounded-b-[20px]'
            >
              <div>{parse(option)}</div>
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
      <SearchIconContainer>
        <ButtonIcon
          iconVariant='search-regular'
          iconSize='s'
          color='dark'
          variant='dark'
          aria-label='search'
          onClick={() => {
            submit(searchText)
          }}
        />
      </SearchIconContainer>
    </SearchBarContainer>
  )
}
